import { ReactNode } from "react";
import background from "../../assets/images/background.svg";
import "./styles.scss";
import Header from "../Header";
import Footer from "../Footer";

interface TProps {
  children: ReactNode | undefined;
}

function Layout(props: TProps) {
  const { children } = props;
  
  return (
    <div className=" w-full h-full bg-black overflow-auto">
      <div className="fixed inset-0 z-0">
        <img className=" object-cover w-full h-full" src={background} alt="" />
      </div>
      <div className="relative z-10">
        <Header />
        {children}
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
