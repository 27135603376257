import { useEffect, useState } from "react";
import { API_URL, BLOCK_EXPLORER_URL } from "../../helpers/consts";
import Chart from "../../components/Chart";
import { formatAddress } from "../../helpers/utils";

function inputToImage(input: number[]) {
    const canvas = document.createElement("canvas");
    canvas.width = 28;
    canvas.height = 28;
    const ctx = canvas.getContext("2d");
    if (ctx) {
        const imageData = ctx.createImageData(28, 28);
        for (let i = 0; i < 784; i++) {
            const value = input[i];
            imageData.data[i * 4] = 255;
            imageData.data[i * 4 + 1] = 180;
            imageData.data[i * 4 + 2] = 48;
            imageData.data[i * 4 + 3] = value === 0 ? 0 : 255;
        }
        ctx.putImageData(imageData, 0, 0);
    }
    return canvas.toDataURL();
}

function Drawings() {

  const [data, setData] = useState<any[]>([]);
  const [guess, setGuess] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${API_URL}/inference_results`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        // console.log(data);
        setData(data)
      });
  }, []);

  const numbers = Array.from({ length: 10 }, (_, i) => i); // Array of numbers from 0 to 9


  return (
    <div className="max-w-4xl bg-[#141414] rounded-xl p-10 mx-auto border border-white border-opacity-15 mb-10">
        <div className="text-white">
            <p className=" text-4xl pb-6 font-semibold argon"> 
                Drawings
            </p>
            <div className="grid grid-cols-4 pb-2">
                <div className="text-[#94969C] uppercase">Drawing</div>
                <div className="">
                    <p className="text-[#94969C] uppercase">Guess</p>
                </div>
                <div className="">
                    <p className="text-[#94969C] uppercase">Scores</p>
                </div>
                <div className="">
                    <p className="text-[#94969C] uppercase">Transaction</p>
                </div>
            </div>
            {
                loading && <div className="text-center mt-4">Loading...</div>
            }
            {
                data && data.map((item: any, index: any) => (
                    <div key={index} className="grid grid-cols-4 py-[16px] border-t border-t-white border-opacity-15">
                        <div className="flex justify-center items-center">
                            <img src={inputToImage(item.input)} className="scale-125" />
                        </div>
                        <div className="flex justify-center items-center pl-2">
                            <p className="text-xl">{item.guess}</p>
                        </div>
                        <div className="flex justify-center items-center">
                            <Chart data={item.output} guess={item.guess} />
                        </div>
                        <div className="flex justify-center items-center">
                            <a href={`${BLOCK_EXPLORER_URL}/tx/${item.transactionHash}`} target="_blank" rel="noreferrer" className="text-white text-sm hover:underline">{formatAddress(item.transactionHash)}</a>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
    );
}

export default Drawings;