import './index.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import reportWebVitals from './reportWebVitals';
import Home from "./pages/home";
import LeaderboardPage from "./pages/leaderboard";
import DrawingsPage from "./pages/drawings";
import Layout from "./components/Layout";
import { BLOCK_EXPLORER_URL, RPC_URL } from './helpers/consts';

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "a9dd7323e40473a854d7c757b6d7cb44";

// 2. Set chains
const testnet = {
  chainId: 3490,
  name: "Neural Testnet",
  currency: "tNET",
  explorerUrl: BLOCK_EXPLORER_URL,
  rpcUrl: RPC_URL,
};

// 3. Create modal
const metadata = {
  name: "Mnist Dapp",
  description: "Draw a digit and get it classified by a neural network",
  url: "https://mnist.neuralexamples.com", // origin must match your domain & subdomain
  icons: ["https://mnist.neuralexamples.com/favicon.ico"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [testnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

export default function App() {
  return (
    <Layout>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/leaderboard" element={<LeaderboardPage />} />
            <Route path="/drawings" element={<DrawingsPage />} />
        </Routes>
      </BrowserRouter>
    </Layout>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
