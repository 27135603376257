import { useEffect, useState } from "react";
import { API_URL, BLOCK_EXPLORER_URL } from "../../helpers/consts";
import { formatAddress, numberWithCommasAndAbbreviations } from "../../helpers/utils";

function getNumberStyle(num: number, guess: number) {
    let style: any = {};
    if (num === guess) {
        style = { background: "linear-gradient(225deg, #D79F3C 0%, #F07D3D 100%)", color: "black", border: "1px orange solid", borderOpacity: 0.15};
    } else {
        style = { background: "#272727", color: "rgba(255,255,255,0.87)", border: "1px rgba(255, 255, 255, 0.16) solid"};
    }
    return style;
}

function Leaderboard() {

  const [data, setData] = useState<any[]>([]);
  const [guess, setGuess] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${API_URL}/best_scores`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setData(data)
      });
  }, []);

  const numbers = Array.from({ length: 10 }, (_, i) => i); // Array of numbers from 0 to 9

  return (
    <div className="max-w-4xl min-h-[85vh] bg-[#141414] rounded-xl p-10 mx-auto border border-white border-opacity-15">
        <p className=" text-white text-4xl font-semibold argon">
            Leaderboard
        </p>
        <p className=" text-gray-400 pb-8">
            Select the number to see the highest scores
        </p>
        <div className="flex justify-center items-center pb-8">
            {numbers.map(number => (
                <button
                    key={number}
                    className="w-[50px] h-[50px] text-2xl font-[500]"
                    style={guess !== undefined && getNumberStyle(number, guess)}
                    onClick={() => setGuess(number)}
                >
                    {number}
                </button>
            ))}
        </div>

        <div className="text-white">
            <div className="grid grid-cols-3 mt-8 pb-2">
                <div className="text-[#94969C] uppercase">Rank</div>
                <div className="ml-4">
                    <p className="text-[#94969C] uppercase">Address</p>
                </div>
                <div className="ml-4">
                    <p className="text-[#94969C] uppercase">Score</p>
                </div>
            </div>
            {
                loading && <div className="text-center mt-4">Loading...</div>
            }
            {
                data && data[guess] && data[guess].map((item: any, index: any) => (
                    <div key={index} className="grid grid-cols-3 border-t border-t-white border-opacity-15 py-4">
                        <div className="text-lg font-bold">{index === 0 ? "👑" : index + 1}</div>
                        <div className="ml-4">
                            <a href={`${BLOCK_EXPLORER_URL}/address/${item.user}`}className="text-sm hover:underline">{formatAddress(item.user)}</a>
                        </div>
                        <div className="ml-4">
                            <p className="text-sm">{numberWithCommasAndAbbreviations(item.score)}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
    );
}

export default Leaderboard;