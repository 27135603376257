import "./styles.scss";

interface TProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
}

function ButtonOutline(props: TProps) {
  const { text } = props;

  return (
    <button className="btn btn-outline" {...props}>
      {text}
    </button>
  );
}

export default ButtonOutline;
