
export default function Footer() {

    return (
        <div className=" my-auto w-[80%] h-full pt-10 pb-2 justify-between items-center inline-flex gap-[32px]">
            <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 32, display: 'flex'}}>
                <a href="https://x.com/neuralorg" className="hover:underline text-xs md:text-base" style={{color: 'rgba(255, 255, 255, 0.64)', fontFamily: 'Argon', fontWeight: '400', wordWrap: 'break-word'}}>X</a>
                <a href="https://discord.gg/neuralorg" className="hover:underline text-xs md:text-base" style={{color: 'rgba(255, 255, 255, 0.64)', fontFamily: 'Argon', fontWeight: '400', wordWrap: 'break-word'}}>Discord</a>
            </div>
            <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 32, display: 'flex'}}>
                <a href="/terms.html" className="hover:underline text-xs md:text-base" style={{color: 'rgba(255, 255, 255, 0.64)', fontFamily: 'Argon', fontWeight: '400', wordWrap: 'break-word'}}>Terms of Use</a>
                <a href="/privacy.html" className="hover:underline text-xs md:text-base" style={{color: 'rgba(255, 255, 255, 0.64)', fontFamily: 'Argon', fontWeight: '400', wordWrap: 'break-word'}}>Privacy Policy</a>
                <p className="text-xs md:text-base" style={{color: 'rgba(255, 255, 255, 0.64)', fontFamily: 'Argon', fontWeight: '400', wordWrap: 'break-word'}}>Neural © 2024</p>
            </div>
        </div>
    )
}