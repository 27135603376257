import abi from "./abi";
import { ethers } from "ethers";

export const getContract = async (walletProvider: any) => {
  const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
  ethersProvider.pollingInterval = 3000;
  const signer = await ethersProvider.getSigner();

  const contract = new ethers.Contract(
    "0xDb78Ad1f678ce379d64c274ae5D8a0a093F79FD6",
    abi,
    signer
  );

  return contract;
};
