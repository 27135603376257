import { RPC_URL } from "./consts";

export const getGasTokenBalance = async (address: string) => {
  try {
    const response = await fetch(`${RPC_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: 1,
        method: "eth_getBalance",
        params: [address, "latest"],
      }),
    });
    const result = await response.json();
    return Number(result["result"]) / 1e18;
  } catch (error) {
    console.error("Error getting gas token balance:", error);
    return 0;
  }
};

export const formatAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

function numberWithCommas(num: string | number) {
  if (typeof num !== "string") {
    num = num.toString();
  }
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const numberWithCommasAndAbbreviations = (num: number) => {
  const abbreviations = ["", "K", "M", "B", "T"];
  const absNumber = Math.abs(num);
  const tier = (Math.log10(absNumber) / 3) | 0;

  if (tier === 0) return numberWithCommas(num);

  const suffix = abbreviations[tier];
  const scaledNumber = num / Math.pow(10, tier * 3);

  const formattedNumber = numberWithCommas(scaledNumber.toFixed(3)); // Limit to three decimal places
  const decimalIndex = formattedNumber.indexOf(".");
  const decimalPart =
    decimalIndex !== -1 ? formattedNumber.slice(decimalIndex) : "";

  return formattedNumber.replace(/\.\d+/, "") + decimalPart + suffix;
};
