import { ReactNode } from "react";
import "./styles.scss";
import { map, max } from "lodash";
import CheckedCircle from "../CheckedCircle";

export interface DataType {
  name: number;
  value: number;
  guess?: boolean;
}

interface TProps {
  data: DataType[]
  completed?: boolean[]
  guess?: number
}

const gradient = "linear-gradient(225deg, #E19C16 14.89%, #FF7524 85.85%), linear-gradient(225deg, color(display-p3 0.843 0.624 0.235) 14.89%, color(display-p3 0.941 0.490 0.239) 85.85%), linear-gradient(0deg, #D9D9D9, #D9D9D9); linear-gradient(0deg, color(display-p3 0.851 0.851 0.851), color(display-p3 0.851 0.851 0.851))"

function Chart({data, completed, guess}: TProps) {
  const maxHeight = 75;
  let highest: number = max(map(data, (val: any) => {
    if (typeof val !== 'number') return val.value
    return val
  }));

  const renderItem = (item: DataType | number, index: number, guess: number | undefined) => {
    if (typeof item !== 'number') {
      return (
          <div className="chart-column" key={item.name}>
            <div
              className="chart-rectangle"
              style={{ height: `${item.value / highest * maxHeight}px`, opacity: item.guess ? "100%" : "25%", width: "24px" }}
            ></div>
            <div className="chart-text">{item.name}</div>
            <div className="chart-text">
              <CheckedCircle checked={(completed !== undefined) && completed[index]}/>
            </div>
          </div>
      )
    } else {
      return (
          <div className="chart-column" key={index}>
            <div
              className="chart-rectangle"
              style={{ height: `${item / highest * (maxHeight * 0.5)}px`, opacity: index === guess ? "100%" : "25%", width: "12px" }}
            ></div>
            <div className=" text-[10px] text-gray-500">{index}</div>
          </div>
        )
    }
  };

  return (
    <div className="chart" style={
        typeof data[0] !== 'number' ? { gap:"8px", minHeight: "120px", paddingTop: "28px"} : {gap:"2px"}}>
      {data.map((item: DataType, index: number) => renderItem(item, index, guess))}
    </div>
  );
}

export default Chart;
