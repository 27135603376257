import { useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import ButtonOutline from "../Button/ButtonOutline";
import "./styles.scss";
import CanvasDraw from "react-canvas-draw";
import { getContract } from "../../helpers/web3";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { BigNumber, ethers } from "ethers";
import Chart, { DataType } from "../Chart";
import abi from "../../helpers/abi";
import { map } from "lodash";
import { BLOCK_EXPLORER_URL } from "../../helpers/consts";

type CompletedType = boolean[];

const defaultData: DataType[] = [
    {
        name: 0,
        value: 0,
    },
    {
        name: 1,
        value: 0,
    },
    {
        name: 2,
        value: 0,
    },
    {
        name: 3,
        value: 0,
    },
    {
        name: 4,
        value: 0,
    },
    {
        name: 5,
        value: 0,
    },
    {
        name: 6,
        value: 0,
    },
    {
        name: 7,
        value: 0,
    },
    {
        name: 8,
        value: 0,
    },
    {
        name: 9,
        value: 0,
    },
];


async function ImageTo28x28(imageData: any, precision: any) {
    let data: any[] = [];
    var image = new Image();
    let prom = new Promise(resolve => {
        image.onload = function() {
            var canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;

            try {
                var context = canvas.getContext('2d');
                context?.drawImage(image, 0, 0);
            } catch {
                return [];
            }

            var imageData = context!.getImageData(0, 0, canvas.width, canvas.height);

            // Now you can access pixel data from imageData.data.
            let dx = Math.floor(image.width / 28);
            let dy = Math.floor(image.height / 28);
            //TODO: This cuts a bit of the image. Fix later
            for (let y = 0; y < image.height - dy; y += dy) {
                for (let x = 0; x < image.width - dx; x += dx) {
                    let numPix = 0;
                    let sum = 0;
                    for (let i = y; i < y + dy; i++) {
                        for (let j = x; j < x + dx; j++) {
                            var index = (y * imageData?.width + x) * 4;
                            var red = imageData.data[index];
                            var green = imageData.data[index + 1];
                            var blue = imageData.data[index + 2];
                            let grayscale = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;
                            sum += grayscale;
                            numPix++;
                        }

                    }
                    data.push(Math.floor(sum / numPix * precision));
                }
            }
            resolve(0);
            //console.log(data.length);
        };
    });
    image.src = imageData;
    await prom;
    return data;

}

function DrawDigit() {
    const canvasRef = useRef(null);
    const { walletProvider } = useWeb3ModalProvider();
    const { address } = useWeb3ModalAccount();

    const [data, setData] = useState<DataType[]>(defaultData);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [tx, setTx] = useState<string>();
    const [completed, setCompleted] = useState<CompletedType>();
    const [pending, setPending] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [tokenID, setTokenID] = useState<number>(-1);

    useEffect(() => {
        if (walletProvider) {
            const fetchCompleted = async () => {
                const contract = await getContract(walletProvider);
                const provider = new ethers.providers.Web3Provider(walletProvider);
                const userAddress = await provider.getSigner().getAddress();
                const completedChain: CompletedType = await contract.getCompleted(userAddress)
                setCompleted((prev: CompletedType | undefined) => {
                    return completedChain
                })
            };
            fetchCompleted();
        }
    }, [address]);

    const handleClear = () => {
        const canvas = canvasRef?.current as any;
        setData(defaultData)
        canvas?.clear();
        setSubmitted(false);
        setTx(undefined);
        setTokenID(-1);
        setError("");
    };

    const handleSubmit = async () => {
        const canvas = canvasRef?.current as any;

        canvas.filter = 'grayscale(100%)';

        const imageData = canvas?.getDataURL();
        const image_encoding: number[] = await ImageTo28x28(imageData, 1e8);

        // console.log(image_encoding);

        if (imageData && walletProvider) {
            const contract = await getContract(walletProvider);
            try {
                setPending(true);
                setTokenID((prev) => -1);
                setError((prev) => "");
                const guessNumber = await contract.infer(
                    image_encoding
                );
                let rec = await guessNumber.wait();
                const provider = new ethers.providers.Web3Provider(walletProvider);
                const userAddress = await provider.getSigner().getAddress();
                provider.polling = true;
                provider.pollingInterval = 500;
                let tx = await provider.getTransactionReceipt(rec.transactionHash);
                setTx(tx.transactionHash);
                setPending(false);
                const guessContract = new ethers.utils.Interface(abi);
                try {
                    let metaDataLog = tx.logs.filter((log: any) => ((log.topics[0] === "0xf8e1a15aba9398e019f0b49df1a4fde98ee17ae345cb5f6b5e2c27f5033e8ce7")));
                    let tid = parseInt(metaDataLog[0].data, 16);
                    if (tid) {
                        setTokenID((prev) => tid);
                    } else {
                        console.log("tokenID evaluates to false", tid);
                    }
                } catch (error) {
                    console.log("Error getting tokenID", error);
                }
                let targetLog = tx.logs.filter((log: any) => (log.address === contract.address) && (log.topics[0] === guessContract.getEventTopic("InferenceResult")));
                let parsedLog = guessContract.parseLog(targetLog[0])
                let probs = parsedLog.args[1] || [];
                let guess = parsedLog.args[2];
                let completedChain: CompletedType = await contract.getCompleted(userAddress)
                setCompleted((prev: CompletedType | undefined) => {
                    return completedChain
                })
                setSubmitted(true)
                setData(map(probs, (prob: BigNumber, i: number) => {
                    return {
                        name: i,
                        value: Math.max(0, prob.toNumber() / 1e8),
                        guess: guess.toNumber() === i
                    }
                }))

            } catch (error) {
                setPending(false);
                setError("Error getting tx receipt.");
                console.log("e", error);
            }
        }
    };

    return (
        <div className="draw-container">
            <div className="draw-form">
                <h2 className="draw-title argon">Draw the Digits</h2>
                <p className="draw-subtitle">
                    Draw a single digit in the empty box and click submit to classify it by the ML model. Draw 0-9 to mint an NFT.
                </p>
                <div className="canvas-container">
                    <CanvasDraw
                        ref={canvasRef}
                        hideGrid
                        hideInterface
                        brushColor="#fff"
                        brushRadius={8}
                        lazyRadius={0}
                        disabled={submitted}
                    />
                </div>
                <div style={{ marginTop: "10px" }} className="flex flex-row justify-center items-center gap-2"> 
                    {
                        error && 
                        <span className="flex my-auto font-normal text-xs leading-relaxed text-center uppercase tracking-wide text-red-400">
                            💥 {error}
                        </span>
                    }
                    {
                        pending &&
                        <div className=" flex flex-row gap-2">
                            <svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span className="flex my-auto font-normal text-xs leading-relaxed text-center uppercase tracking-wide text-white -translate-y-[1px]">Processing...</span>
                        </div>
                    }
                    {
                        tx &&
                        <a target="_blank" href={`${BLOCK_EXPLORER_URL}/tx/${tx}`}> 
                            <span className="flex my-auto font-normal text-xs leading-relaxed text-center uppercase tracking-wide text-[#FA7F22] hover:underline"> 
                                Tx Hash 
                            </span> 
                        </a> 
                    }
                    {
                        (tx && tokenID > -1) && 
                        <span className="text-white">{" "}|{" "}</span>
                    }
                    {
                        tokenID > -1 &&
                        <a target="_blank" href={`${BLOCK_EXPLORER_URL}/token/0x15E077052053339659D9f8eE4Bd47f9aF2ECBA63/instance/${tokenID}`}>
                            <span className="flex my-auto font-normal text-xs leading-relaxed text-center uppercase tracking-wide text-[#ffc074] hover:underline">  
                                View NFT 🎉
                            </span>
                        </a>
                    }
                </div>
                <Chart data={data} completed={completed} />
                <div className="button-group">
                    <ButtonOutline onClick={handleClear} text="Clear" />
                    <Button className="btn primary" disabled={submitted} onClick={handleSubmit} text={"Submit"} />
                </div>
            </div>
            <div className="draw-footer">
                <p className="draw-footer-text backdrop-blur-sm">
                    The model was trained against the MNIST dataset. It is a convolutional neural network (CNN) built using Keras/TensorFlow on a Google Tensor Processing Unit (TPU).  Source code on {" "}
                    <a href="https://github.com/onnx/models/tree/ddbbd1274c8387e3745778705810c340dea3d8c7/validated/vision/classification/mnist" target="_blank" className="highline">Github</a>.
                </p>
            </div>
        </div>
    );
}

export default DrawDigit;
