import LogoIcon from "../../assets/images/logo/logo.svg";
import MetamaskIcon from "../../assets/images/metamask-icon.png";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import "./styles.scss";
import { formatAddress } from "../../helpers/utils";

function Header() {
  const { open } = useWeb3Modal();
  const { address } = useWeb3ModalAccount();


  const handleConnect = () => {
    const address = localStorage.getItem("address");
    open();
    if (address) {
      localStorage.removeItem("address");
    }
  };

  

  const getStyle = (path: string) => {
    let style: any = { fontSize: 14, fontWeight: '400', textTransform: 'uppercase', letterSpacing: 1.40, wordWrap: 'break-word'}
    if (window.location.pathname === path)
      style['color'] = 'rgba(255, 255, 255, 0.87)';
    else
      style['color'] = 'rgba(255, 255, 255, 0.64)';
    return style;
  }

  return (
    <>
      <div className="max-w-[1440px] mx-auto flex justify-end md:justify-between items-center px-2 md:px-12 py-6">
        <img src={LogoIcon} alt="neural logo" className=" justify-self-start hidden md:block" />
        <button
          className=" text-white border-2 border-white border-opacity-15 px-4 py-2 rounded-full text-sm flex flex-row gap-x-2 bg-black inset-0 md:static -translate-y-3 md:translate-y-[0px] z-10"
          onClick={handleConnect}
        >
          {
            address ? <img src={MetamaskIcon} alt="" style={{width: 20, height: 20, borderRadius: 20}} /> : <></>
          }
          {address ? formatAddress(address) : "Connect"}
        </button>
      </div>
      <div className=" flex w-full justify-center -mt-[26px] md:-mt-[68px] pb-4">
        <div className="w-fit px-4 py-3 " style={{background: 'linear-gradient(0deg, black 0%, black 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%)', borderRadius: 40, border: '1px rgba(255, 255, 255, 0.16) solid', justifyContent: 'flex-start', alignItems: 'center', gap: 40, display: 'inline-flex'}}>
          <a href="/" className="argon" style={{justifyContent: 'center', alignItems: 'center', gap: 10, display: 'flex'}}>
            <div style={getStyle('/')}>Home</div>
          </a>
          <a href="/leaderboard" className="argon" style={{justifyContent: 'center', alignItems: 'center', gap: 10, display: 'flex'}}>
            <div style={getStyle('/leaderboard')}>Leaderboard</div>
          </a>
          <a href="/drawings" className="argon" style={{justifyContent: 'center', alignItems: 'center', gap: 10, display: 'flex'}}>
            <div style={getStyle('/drawings')}>drawings</div>
          </a>
        </div>
      </div>
    </>
  );
}

export default Header;
